import { useEffect, useState } from "react";
import Icon from "./components/Icon";

export default function App() {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log(event);
    console.log(event.target.elements);
    let reportText = "";
    for (const input of event.target.elements) {
      if (!input.value) {
        continue;
      }
      reportText += `- ${input.dataset.name}: ${input.value} ${input.dataset.unit}\n`;
    }
    setShowModal(true);
    setModalContent(reportText);
  };

  useEffect(() => {
    // If you're using Create React App and the file is in the public folder
    fetch("/ingredient.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setData(data))
      .catch((error) =>
        console.error(
          "There has been a problem with your fetch operation:",
          error
        )
      );
  }, []);
  return (
    <>
      <div className="p-4">
        <h1 className="text-lg font-bold mb-5">Nhập Nguyên Liệu</h1>
        <form onSubmit={handleFormSubmit}>
          <div>
            {data.length &&
              data.map((item) => (
                <div className="bg-white border border-gray-200 rounded-lg shadow p-4 mb-5">
                  <div className="text-base font-semibold leading-7 text-gray-900">
                    <span className="mr-2">{item.group_name} </span>
                    <Icon name={item.icon} />
                  </div>

                  <div className="mt-5">
                    {item.items.map((childItem, index) => (
                      <div className="mb-3">
                        <label
                          for={`item-${index}`}
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {childItem.name}
                        </label>
                        <div class="mt-2 flex flex-wrap items-stretch w-full mb-4 relative rounded-md shadow-sm border-0">
                          <input
                            type="number"
                            name={`item-${index}`}
                            id={`item-${index}`}
                            data-name={childItem.name}
                            data-unit={childItem.unit}
                            class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-r-none px-3 relative"
                            placeholder="Nhập số lượng"
                          />
                          <div class="flex -mr-px">
                            <span class="flex items-center leading-normal bg-grey-lighter rounded rounded-l-none border border-l-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm">
                              {childItem.unit}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}

            <div className="mt-5 flex items-center justify-end gap-x-6">
              <button
                type="submit"
                className="rounded-md bg-green-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Báo cáo
              </button>
            </div>
          </div>
        </form>
      </div>
      <div
        tabindex="-1"
        class={`${
          showModal ? "" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
      >
        <div class="relative p-4 w-full max-w-2xl max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Báo Cáo Số Lượng
              </h3>
              <button
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setShowModal(false)}
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <div class="p-4 md:p-5 space-y-4 whitespace-pre">
              {modalContent}
            </div>
            <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                onClick={() => setShowModal(false)}
                type="button"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Gửi báo cáo
              </button>
              <button
                onClick={() => setShowModal(false)}
                type="button"
                class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Hủy
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
