import { icons } from 'lucide-react';

const Icon = ({ name }) => {
  console.log(icons);
  const LucideIcon = icons[name];
  console.log(LucideIcon);

  return <LucideIcon color="black" size={24} style={{display: "inline"}}/>;
};

export default Icon;